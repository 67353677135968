/**
* Generated automatically at built-time (2024-12-10T10:17:27.893Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-nordic-line-outdoorkueche",templateKey: "sites/105-380303ff-fec4-4bd2-a602-8b747a1c5357"};