/**
* Generated automatically at built-time (2024-12-10T10:17:27.757Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "outdoorkueche-bauen-ratgeber",templateKey: "sites/105-aa028354-1531-4a56-b1d5-e4b3c0b37653"};